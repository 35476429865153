import './App.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
// import 'antd/dist/antd.css';

import LoginPage from "./pages/login";
import HomePage from "./pages/home";
import ProfilePage from "./pages/profile";
import AboutPage from "./pages/about";
import GraphEditor from "./pages/graphEditor";
import GraphStudier from "./pages/graphStudier";
import { getAuth } from 'firebase/auth'; // Firebase v9+
import { getDatabase } from 'firebase/database'; // Firebase v9+
import { initializeApp } from "firebase/app";

import React, {Component, useState, useEffect} from "react"


// Firebase information
import { FirebaseAppProvider, AuthCheck, AuthProvider, useFirestoreDocData, useFirebaseApp,
  useFirestore, SuspenseWithPerf } from 'reactfire';


const firebaseConfig = {
  apiKey: "AIzaSyDsMYjFoQhULlJZLBv--gki5L6fO8UB1M0",
  authDomain: "tano-28745.firebaseapp.com",
  databaseURL: "https://tano-28745.firebaseio.com",
  projectId: "tano-28745",
  storageBucket: "tano-28745.appspot.com",
  messagingSenderId: "301188524305",
  appId: "1:301188524305:web:ed3531c6e39bcc10e1c4b0",
  measurementId: "G-6VQZTC3EH2"
};

document.body.style.backgroundColor = "#201c21";
//#201c21

export default function App() {

  /*
  This handles app routing. Firebase checks for user authentication
  and if the user is not logged in then the default login page is shown.
  If the user is logged in, then they will be taken to the page specified
  in the current url. To switch pages using code, use
  history.push("/targetpath")
  */

  const app = initializeApp(firebaseConfig);

  // initialize Database and Auth with the normal Firebase SDK functions
  const auth = getAuth(app);

// <AuthCheck fallback={<LoginPage />}>
//
// </AuthCheck>

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AuthProvider sdk={auth}>
        <BrowserRouter>

              <Routes>
                <Route path="/" element={<LoginPage/>} />
                <Route path="/home" element={<HomePage/>} />
                <Route path="/login" element={<LoginPage/>} />
                <Route path="/profile" element={<ProfilePage/>} />
                <Route path="/about" element={<AboutPage/>} />

                <Route path="/edit/:graphID" element={<GraphEditor/>} />
                <Route path="/study/:graphID" element={<GraphStudier/>} />
                <Route path="/demo/:graphID" element={<GraphStudier/>} />

              </Routes>
        </BrowserRouter>
      </AuthProvider>
    </FirebaseAppProvider>

  )
}


// This is an example of a route with a parameter
// <Route exact path= "/flashcardeditor/:id" component={FlashcardEditor}/>
