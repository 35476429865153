// If a user is logged in, redirect to home on the loggin page
// If not, redirect to login page


import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged, currentUser } from "firebase/auth";


function CheckLoggedIn(props) {
  const auth = getAuth();
  const navigate = useNavigate()

  auth.onAuthStateChanged(user => {
    if(user) {
      console.log("user is authed");
      // navigate("home")
    } else {
      console.log("user is not authed");
      setTimeout(()=>{
          navigate("/login")
      },200)

    }
  });

  return <div>{props.children}</div>;

}


export default CheckLoggedIn;
