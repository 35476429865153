import React, {Component, useState, useEffect,useReducer }  from "react"


function useDeletePress(onPress) {
	// Holding the delete key down will trigger sequential deletion
	// of characters as long as it is held down
	useEffect(() => {
		// If pressed key is our target key then set to true
		function downHandler({ key }) {
			if (key == "Backspace")
				onPress()
		}

		// Add event listeners
		window.addEventListener('keydown', downHandler);

		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', downHandler);
		};
	});

	return "";
}


function useKeyPress(targetKey, onPressDown = () => {}, onPressUp = () => {}) {
	// State for keeping track of whether key is pressed
	const [keyPressed, setKeyPressed] = useState(false);

	useEffect(() => {
		// If pressed key is our target key then set to true
		function downHandler({ key }) {
			if ((key === targetKey) && !keyPressed) {
				setKeyPressed(true);
				onPressDown();
			}
		}

		// If released key is our target key then set to false
		const upHandler = ({ key }) => {
			if (key === targetKey) {
				setKeyPressed(false);
				onPressUp();
			}
		};

		// Add event listeners
		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);

		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	});

	return keyPressed;

}


function useAlphaNumbericPress(onPress) {
	// State for keeping track of whether key is pressed
	const [whichKeyPressed, setWhichKeyPressed] = useState("");

	useEffect(() => {
		// If pressed key is our target key then set to true
		function downHandler({ key }) {
			if (key.length == 1) {
					setWhichKeyPressed(key);
					onPress(key);
			}
		}

		// Add event listeners
		window.addEventListener('keydown', downHandler);

		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', downHandler);
		};
	});

	return whichKeyPressed;
}




export default useKeyPress
export {useAlphaNumbericPress, useDeletePress};
