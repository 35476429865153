/*
This component allows the user to study a graph, but not edit it.
*/
import {useParams} from "react-router-dom"
import React, {useState, useEffect}  from "react"
import useKeyPress from "../components/GlobalKeyPresser";
import { getFirestore, collection, doc, setDoc, getDoc} from "firebase/firestore";
import TanoGraph from "../components/TanoGraph"
import cuid from 'cuid';
import NavBar from "../components/Nav";

function GraphStudier() {

    // Get graph id from url
    let { graphID } = useParams();
    const [graph, setGraph] = useState({ nodes: [], edges: []})
    const [network, setNetwork] = useState(null)
    const [init, setInit] = useState(false);
    // Keeps track of the current node being studied
    const [currentNodeIndex, setCurrentNodeIndex] = useState(0);

    // Keeps track of most recently revealed node
    const [revealedNodeIndex, setRevealedNodeIndex] = useState(-1);

    var resizeTimer;
    const [graphKey, setGraphKey]=useState(cuid())

    // Node with ? is show first, then text is revieled
    const [nodeRevealed, setNodeRevealed] = useState(false);

    // // Make graph rerender
    function handleResize() {
      // Throttle calls resize
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        setGraphKey(graphKey=>cuid())
      }, 50);
    }


    useEffect(()=>{
        if (!init && graph.nodes[0]) {
          setInit(true)
          window.addEventListener('resize', handleResize)
          network.focus(graph.nodes[0].id,  { scale:1.5})

        }
    },[network, graph.nodes])



    const incrementNode = () => {

      // If we haven't reached the end of the sequence...
      if (!(revealedNodeIndex == graph.nodes.length-1 && currentNodeIndex == graph.nodes.length-1)) {
        if (revealedNodeIndex == currentNodeIndex ) {
          // Order is important for rendering

          setTimeout(()=> {
            network.focus(graph.nodes[currentNodeIndex + 1].id,  { scale:1.5, animation:{duration:400, easingFunction:"easeInOutCubic"}})
          }, 100)
          setCurrentNodeIndex(currentNodeIndex + 1);

        } else {
          setRevealedNodeIndex(revealedNodeIndex+1);
        }
      }
    }

    const decrementNode = () => {

      // If we haven't reached the start of the sequence...
      if (!(revealedNodeIndex==-1 && currentNodeIndex==0)) {
        if (revealedNodeIndex < currentNodeIndex) {
          // Order is important for rendering
          setTimeout(()=> {
            network.focus(graph.nodes[currentNodeIndex - 1].id,  { scale:1.5, animation:{duration:400, easingFunction:"easeInOutCubic"}})
          }, 100)

          setCurrentNodeIndex(currentNodeIndex - 1);
        } else {
          setRevealedNodeIndex(revealedNodeIndex - 1);
        }
      }
    }


    // Keys to go to next node
    const enterPressed = useKeyPress('Enter', incrementNode, ()=>{});
    const spacePressed = useKeyPress(' ', incrementNode, ()=>{});
    const rightArrowPressed = useKeyPress('ArrowRight', incrementNode, ()=>{});

    // Keys to go back
    const leftArrowPressed = useKeyPress('ArrowLeft', decrementNode, ()=>{});


    // Load graph data from filebase
    // TODO: make sure user is the owner
    const getGraphData = async () => {
      const db = getFirestore();
      console.log("graph id:",graphID);
      const docRef = doc(db, "graphs", graphID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setGraph({nodes: docSnap.data().nodes, edges: docSnap.data().edges});
      } else {
        console.log("No such document!");
      }

    }

    useEffect(() => {
      getGraphData()
    }, [])

    return (
      <div style={{ height:"100vh"}}>
        <NavBar/>
        <TanoGraph
          key={graphKey}
          click={()=>{}}
          setNetwork={setNetwork}
          addNodeAtCoords={()=>{}}
          selectedNodeID={()=>{}}
          nodeClicked={()=>{}}
          clickedEmptySpace={()=>{}}
          edgeClicked={()=>{}}
          studying={true}
          currentNodeIndex={currentNodeIndex}
          revealedNodeIndex={revealedNodeIndex}
          updateNodePositions={()=>{}}
          graph = {{nodes:graph.nodes,edges:graph.edges}}
        />
      </div>
    )
}

export default GraphStudier;
