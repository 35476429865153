import "./login.css"

// import { FirebaseAppProvider, AuthCheck, AuthProvider, useFirestoreDocData, useFirebaseApp,
//   useFirestore } from 'reactfire';
//
import { useNavigate } from "react-router-dom";

import React, {useState, useEffect} from "react"
import { getAuth, signInWithEmailAndPassword, sendSignInLinkToEmail, getRedirectResult, signInWithRedirect, setPersistence, browserLocalPersistence, signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import htmlContent from '../index.html'

import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import {PrimaryButton, SecondaryButton} from "../components/Buttons";


/*
This is the login page that users see if they use the app
and are NOT logged in. They should see a username and password field,
forgot password button, etc.
*/


function SignInPane() {

}

function CreateNewAccountPane() {

}


function LoginPage() {


  // // initialize Database and Auth with the normal Firebase SDK functions
//  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth();
  const navigate = useNavigate()


  getRedirectResult(auth)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access Google APIs.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential.accessToken;
    //
    // // The signed-in user info.
    const user = result.user;
    console.log("done");
  }).catch((error) => {
    // Handle Errors here.
    console.log("error:", error);
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // // The email of the user's account used.
    // const email = error.customData.email;
    // // The AuthCredential type that was used.
    // const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });


  //
  auth.onAuthStateChanged(user => {
    console.log("auth change, user:",user);
    if(user) {
      console.log("nav home");
      navigate("/home")
    }
  });

  const [email, setEmail] = useState("") //"jackduryea@gmail.com"
  const [password, setPassword] = useState("") // 654321


  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://tano-28745.web.app/',
    // This must be true.
    handleCodeInApp: true,

  };




    function signIn() {

      console.log("sign in clicked");
      const auth = getAuth();
      var provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/plus.login');
      signInWithRedirect(auth, provider)
       // setPersistence(auth, browserLocalPersistence).then(() => {
       //
       // })

      // setPersistence(auth, browserLocalPersistence)
      // .then(()=>{
      //
      //   signInWithPopup(auth, provider)
      //   .then((result) => {
      //     // This gives you a Google Access Token. You can use it to access the Google API.
      //     const credential = GoogleAuthProvider.credentialFromResult(result);
      //
      //     const token = credential.accessToken;
      //
      //     // The signed-in user info.
      //     const user = result.user;
      //
      //
      //     navigate("/home")
      //   }).catch((error) => {
      //     // Handle Errors here.
      //     console.log("error");
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     // The email of the user's account used.
      //     const email = error.customData.email;
      //     // The AuthCredential type that was used.
      //
      //     console.log("error", errorCode, errorMessage, email);
      //
      //
      //     const credential = GoogleAuthProvider.credentialFromError(error);
      //     // ...
      //   });
      //
      // })


    // console.log("sign in");
    // sendSignInLinkToEmail(auth, email, actionCodeSettings)
    // .then(() => {
    //   console.log("success");
    //   // The link was successfully sent. Inform the user.
    //   // Save the email locally so you don't need to ask the user for it again
    //   // if they open the link on the same device.
    //   window.localStorage.setItem('emailForSignIn', email);
    //   // ...
    // })
    // .catch((error) => {
    //   console.log("error");
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   alert("Please try again")
    //   // ...
    // });

    // signInWithEmailAndPassword(auth, email, password).then(()=> navigate("/home")).catch(function(error) {
    //   alert("Account doesn't exist, sorry!")
    // });
  }


  // <Form.Control className="password" type="password" onChange={ev => setPassword(ev.target.value)} placeholder="password" value={password} / >
  // <br/>

  // <Stack gap={1} direction="horizontal">
  //   <SecondaryButton onClick={()=>{}}>new user</SecondaryButton>{' '}
  //   <SecondaryButton onClick={()=>{}}>forgot</SecondaryButton>{' '}
  //
  // </Stack>

  //</SecondaryButton className = "my-button signIn" variant="primary" onClick={signIn}>sign in</SecondaryButton>{' '}


  // <Form.Control className="email text-center" onKeyPress={handleKeyPress} onChange={ev => setEmail(ev.target.value)} placeholder="email (hit enter)" value={email} / >

  function handleKeyPress(event) {
    if (event.key == "Enter")
      signIn()
  }


  //)


  // return (
  //         <div dangerouslySetInnerHTML={ {__html: htmlContent} } />
  //   );
  return (
    <div  className="wrapper" >
    <Stack gap={1} className="col-md-5 mx-auto loginStack" style={{maxWidth:"400px"}}>
      <center>
        <h1 className="title">TANO</h1>
      </center>

      <PrimaryButton onClick={signIn}>Sign In</PrimaryButton>{' '}

      <div style={{height:"10px"}}></div>



      <br/>

      <SecondaryButton onClick={()=>navigate("/demo/" + "8dRP11XxVVLehwHgPCn4")}>Demo: Krebs Cycle</SecondaryButton>{' '}

    </Stack>

    </div>
  )
}


export default LoginPage;
