import "./home.css"

import { getAuth, signInWithEmailAndPassword, signOut,onAuthStateChanged, currentUser } from "firebase/auth";
//import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

import React, {Component,useState, useEffect} from "react"
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import {PrimaryButton, TransparentButton} from '../components/Buttons';

import DropdownButton from 'react-bootstrap/DropdownButton';
// import { Popover } from 'antd';

import { getFirestore, collection, deleteDoc, addDoc,updateDoc, deleteField, getDocs, doc, getDoc, setDoc } from "firebase/firestore";

import NavBar from "../components/Nav";
import CheckLoggedIn from "../components/CheckLoggedIn";
/*
This is the home page that users see if they use the app
and are already logged in. They should see their sets, recent activity,
nav bar, etc.
*/




function MainContentHeader() {
  // TODO: style
  return (
    <h1 className="main-content-header"> Graphs</h1>
  )
}



function MainContentCreateButton(props) {
  // TODO style
  return (
    <div style={{width:"100px"}}>
    <PrimaryButton className="main-button" onClick={props.action}> create + </PrimaryButton>
    </div>
  )
}


function MainContentTableHeader(props) {
  /*
  Displays the column headers for the graph table
  */
  return (
    <thead style={{borderBottom:"1px solid white", marginBottom:"30px"}}>
      <tr >
        <th onClick={()=>props.sortBy("name")}>Name</th>

        </tr>
    </thead>
  )
}


function GraphListItem(props) {
  /* A single row in the graph list table. */


  const db = getFirestore();

  const [show, setShow] = useState(false);
  const toggleDropdown = (e) => setShow(!show)
  const navigate = useNavigate();


  function askDeleteGraph() {
    if (window.confirm('Are you sure you want to DELETE this set?')) {
      console.log('Thing set was deleted');
      props.deleteGraph(props.data.id)

    } else {
      // Do nothing!
      console.log('Cool!');
    }
  }

  function deleteClicked(event) {
    askDeleteGraph()
    event.stopPropagation()
  }


  const dropdown = (

    <DropdownButton
      className="drop"
      size="sm"
      variant="secondary"
      show={show}
      title="+"
      drop="none"
      onMouseLeave={toggleDropdown}
      onMouseEnter={toggleDropdown}>

      <Dropdown.Item className="drop-item" href={"/edit/" + props.data.id} >edit</Dropdown.Item>
      <Dropdown.Item className="drop-item" href={"/study/" + props.data.id} >study</Dropdown.Item>
      <Dropdown.Item className="drop-item" onClick={deleteClicked} >delete</Dropdown.Item>
    </DropdownButton>
  )




  return (

    <tr className="table-row" onClick={()=>navigate("/study/" + props.data.id)}>
      <td className="row-name"> {props.data.name}</td>
      {dropdown}
    </tr>



  )
}



function MainContentTable(props) {
  /*
  Displays list of graphs and info about them, one graph per row.
  Graphs can be sorted by column headings and searched for.

  */

  // Fake data to use for now. This will be queried from firebase.
  // const [graphList, setGraphList] = useState([
  //   {name:"Kreb's Cycle", num_nodes:10, last_seen:1, due:2, id:"1234124Aasdga"},
  //   {name:"Glycolysis", num_nodes:4, last_seen:3, due:3, id:"1234124Aas6246dga"},
  //   {name:"Pentose Phosphate Pathway", num_nodes:8, last_seen:7, due:10, id:"12a32534124Aasdga"},
  //   {name:"Another one", num_nodes:2, last_seen:3, due:2, id:"12341asga24Aasdga"}
  // ])


  function sortBy(columnName) {

  }

  console.log("props graphList;", props.graphList);

  // A list of the graphs
  const getGraphItemList = () => Object.keys(props.graphList)
  .map(graphID => {return {name: props.graphList[graphID].name, num_nodes:10, last_seen:1, due:2, id:graphID}})
  .sort((a,b) => a.name > b.name ? 1 : -1).map((graphData,i) => {return<GraphListItem data={graphData} deleteGraph={props.deleteGraph} key={i}/>})


  return (
    <div>


      <Table responsive borderless style={{color:"white", maxWidth:"300px"}}>
        <MainContentTableHeader sortBy={sortBy} />
        <div style={{height:"10px"}}/>
        <tbody className="table-body" >
          {getGraphItemList()}
        </tbody>
      </Table>
    </div>
  )

}

function MainContent() {
  /*
  This component displays the main content in the home page.
  */

  const auth = getAuth();
  const navigate = useNavigate()
  const db = getFirestore();


  const [graphs, setGraphs] = useState([])
  const [userID, setUserID] = useState("")

  onAuthStateChanged(auth, (user) => {
    if (user)
      setUserID(user.uid) // User is signed in
  });


  function deleteGraph(graphID) {
    // Delete graph document and remove it from list of graphs
    // for the user


    deleteDoc(doc(db, "graphs", graphID))
    .then(()=> {
      // Remove graph from list of graph
      const graphRef = doc(db, 'graph_lists', userID);

      updateDoc(graphRef, {
        [graphID]: deleteField()
      })
    })
    .then(()=> {
      // Update data in main page to reflect new graph list
      loadData()
    })

  }

  // Load data if exists, create new doc if not
  const loadData = async () => {

      console.log("user id:", userID);

      const docRef = doc(db, "graph_lists", userID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setGraphs(docSnap.data());
      } else {
        initGraphList(userID)
      }
  }


  // Create the graph list doc if it doesn't exist
  // (needed for new users)
  const initGraphList = async (userID) => {
    if (userID != "") {
      await setDoc(doc(db, "graph_lists", userID), {

    });
  }
}

  const addGraphToList = async (graphID) => {
    // When creating a new graph, update the list of the users graph
    const docRef = doc(db, "graph_lists", userID);
    await updateDoc(docRef, {
        [graphID]:  {name:"new graph"}
    });
  }


  const createNewGraph = async () => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "graphs"), {
      name: "",
      nodes: [],
      edges:[]
    });

    addGraphToList(docRef.id)

    navigate("/edit/" + docRef.id)
  }

  useEffect(()=> {
    if (userID != "")
      loadData()
  },[userID])


  return (
    <div class="main-content">



      <div >
        <Stack direction = "horizontal" gap={3}>
          <MainContentHeader/>
          <MainContentCreateButton action={createNewGraph}/>
        </Stack>
      </div>

      <br/>

      <div style={{width:"80%", minWidth:"500px"}}>
        <MainContentTable graphList={graphs} deleteGraph={deleteGraph}/>
      </div>
    </div>
  )
}

function HomePage() {

  return (
    <CheckLoggedIn>
      <div class="main-container">
        <NavBar/>
        <MainContent />
      </div>
    </CheckLoggedIn>
  )
}


export default HomePage;
