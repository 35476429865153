/*
Custom buttons
*/
import "./Button.css"

import React, {useState, useEffect} from "react"
import Button from 'react-bootstrap/Button';


function PrimaryButton(props) {
  return (
    <Button className="all-custom-button custom-primary-button" onClick={props.onClick}>
      {props.children}
    </Button>
  )
}

function SecondaryButton(props) {
  return (
    <Button className="all-custom-button custom-secondary-button" onClick={props.onClick}>
      {props.children}
    </Button>
  )
}

function TransparentButton(props) {
  return (
    <Button className="all-custom-button custom-transparent-button" onClick={props.onClick}>
      {props.children}
    </Button>
  )
}


export {PrimaryButton, SecondaryButton, TransparentButton};
