import "./home.css"
import {PrimaryButton} from '../components/Buttons';
import { getAuth, signInWithEmailAndPassword, signOut,onAuthStateChanged, currentUser } from "firebase/auth";
import NavBar from "../components/Nav";
import { useNavigate } from "react-router-dom";
import CheckLoggedIn from "../components/CheckLoggedIn";



function MainContent() {
  const auth = getAuth();
  const navigate = useNavigate()


//



  function logout() {
    console.log("logout");

    auth.signOut();

    navigate("/")

  }

  return (
    <center>
      <div style={{width:"200px", marginTop:"50px"}}>
     <PrimaryButton className="main-button" onClick={logout}> logout </PrimaryButton>
     </div>
     </center>
  )

}



function ProfilePage() {
  return (
    <CheckLoggedIn>
      <div class="main-container">
        <NavBar/>
        <MainContent />
      </div>
    </CheckLoggedIn>

  )
}

export default ProfilePage
