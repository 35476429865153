/* Component for displaying a pathway graph. This can and should
   be resused for studying and editing graphs.
*/

import React, {
    Component,
    useState,
    useEffect,
    createRef,
    useRef,
  } from "react"


import VisGraph, {
  GraphData,
  GraphEvents,
  Options,
} from 'react-vis-graph-wrapper';



import "./TanoGraph.css";




const backgroundColor="#201c21"
const primaryColor = "#009980"

// Main graph. Contains details on how graph behaves, looks, etc.
function TanoGraph(props) {


  // Styles a node according to if that node is selected, hidden, etc.
  function stylizeNode(node, i) {

    var label = node.label
    var nodeBackgroundColor = backgroundColor

    // Set border color
    var borderColor = "#1d4e73"
    if (props.studying) {
      if ( i < props.currentNodeIndex)
        label = node.label
      else {
        nodeBackgroundColor = primaryColor
        if (i <=  props.revealedNodeIndex)
          label = node.label
        else {
              label = "?" // node is unseen

        }

      }

      if (i == props.currentNodeIndex) // Node is next up in studying
        borderColor = "white"
    }



    return {
      id: node.id,
      label: label,
      x:node.x,
      y:node.y,
      shape:node.shape,
      margin:5,
      borderWidth: 3,
      mass:1.5,
      fixed:false,
      physics:false,
      widthConstraint:{
        maximum:140,
      },
      labelHighlightBold:false,
      color:{
        background:nodeBackgroundColor,
        border:primaryColor,
        highlight:{
            background:primaryColor,
            border:primaryColor,
          }
        },
      font:{
        color:"white",
        size:20,
      }
    }
  }

  // Styles an edge according to if that node is selected, hidden, etc.
  function stylizeEdge(edge) {
    var color = "#2b6d9e"
    var useMiddle = false

    if (edge.type=="bar") {
      color="red"
      useMiddle=true
    }

    return {
      from: edge.from,
      to: edge.to,
      label: edge.label,
      id: edge.id,
      arrowStrikethrough: true,
      widthConstraint:200,
      arrows: {
        to:{
          type: "triangle",
          scaleFactor:0.7,
        }
      },
      color: primaryColor, width:3, labelHighlightBold:false,
      font:{
        "size":18,
        color:"white",
        background:backgroundColor,
        strokeWidth:1,
        align:"horizontal",
      },
      scaling:{
        max:1,
      }
    }
  }

  function styleAllEdges(edges) {
    return edges.map(e=> stylizeEdge(e))
  }

  function styleAllNodes(nodes) {
    if (props.studying)
      return nodes.filter((n, i) => i <= props.currentNodeIndex).map((n,i) => stylizeNode(n,i))
    else
      return nodes.map((n,i) => stylizeNode(n,i))
  }

  // Defines what events trigger what actions
  const events:GraphEvents = {

      // Drag node around
      dragging: function(event) {
        var { nodes, edges } = event;
        if (nodes.length==1)
          props.nodeClicked(nodes[0], false)
      },



      dragEnd:function(event) {
        var { nodes, edges } = event;
        if (nodes.length == 1)
          props.updateNodePositions(nodes[0])
      },

      // Something clicked on
      select: function(event) {
        // Triggered when either an edge or node is clicked
        var { nodes, edges } = event;
        // Nothing selected
        if (nodes.length == 0 && edges.length == 0)
          props.clickedEmptySpace(event.pointer.canvas)

        // Node clicked
        if (nodes.length==1)
          props.nodeClicked(nodes[0],true)

        // Edge clicked
        if (edges.length==1 && nodes.length==0)
          props.edgeClicked(edges[0])
      },

      // Double click: add new node
      doubleClick:function(event) {
        props.click()
        props.addNodeAtCoords(event.pointer.canvas)
      },

    };

  // Options for how the graph should behave
  var options: Options = {
    height: "100%",
    layout: {
        randomSeed:1,
      },
    edges: {
       color: "#000000",
       smooth: {
         type: "dynamic",
         forceDirection: "none",
         roundness: 0.9
       },

    },

    physics:{

    repulsion: {
      centralGravity: 0.2,
      springLength: 100,
      springConstant: 0.1,
      nodeDistance: 200,
      damping: 0.90
    },

    maxVelocity: 30,
    minVelocity: 0.1,
    solver: 'repulsion',
    stabilization: {
      enabled: true,
      iterations: 10,
      updateInterval: 100,
      onlyDynamicEdges: false,
      fit: true
    },
    timestep: 0.5,
    adaptiveTimestep: true,
    wind: { x: 0, y: 0 }
  }
}

  // Node and edge data
  const graph: GraphData = {
    edges:styleAllEdges(props.graph.edges),
    nodes:styleAllNodes(props.graph.nodes)
  }


  const currentNodeID = props.graph.nodes[props.currentNodeIndex] ? props.graph.nodes[props.currentNodeIndex].id : null

  //console.log("currentNodeID:",currentNodeID);

  return (
    <div style={{height:"100vh", backgroundColor:backgroundColor, position:"fixed", top:"0px"}}>

        <VisGraph
          id = "mynetwork"
          className="vis-network"
          options={options}
          graph={graph}
          events={events}
          getNetwork={network => {
            network.setOptions(options);
            props.setNetwork(network)
          //  if (currentNodeID)
            //  network.focus(currentNodeID)
          }}
        />
      </div>
    );
}

export default TanoGraph;
