// Good reference: https://medium.com/@martin.crabtree/react-creating-an-interactive-canvas-component-e8e88243baf6
// https://visjs.github.io/vis-network/examples/


import React, {useState, useEffect, createRef}  from "react"

import useKeyPress, {useAlphaNumbericPress, useDeletePress} from "../components/GlobalKeyPresser";
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import TanoGraph from "../components/TanoGraph"
import cuid from 'cuid';

// import { Drawer, Button } from 'antd';
// import { DatePicker, Space } from 'antd';

// import { Input } from 'antd';

import {useParams} from "react-router-dom"
import { getFirestore, collection, doc, setDoc, getDoc, updateDoc} from "firebase/firestore";
import { getAuth,onAuthStateChanged } from "firebase/auth";

import NavBar from "../components/Nav";
import OutsideAlerter from "../components/OutsideAlerter";
import {PrimaryButton} from "../components/Buttons";
import "./graphEditor.css"

// Common code used for both creation and editing
function PathwayMakerInner(props) {

    // Keeps track of selected nodes and edges
    const [selectedNodeID, setselectedNodeID] = useState(-1)
    const [selectedEdge, setSelectedEdge] = useState(-1)

    // Give each node and edge a unique id
    const [edgeIDtracker, setEdgeIDtracker] = useState(0)

    // Keeps track of the nodes and edges in the simulatiion
    const [nodes, setNodes] = useState(["Click me!"])
    const [edges, setEdges] = useState([])

    // Use this when the user selects "connect nodes"
    // edge will be draw from first node to second node clicked
    const [connectingNodesOn, setConnectingNodesOn] = useState(false)
    const [network, setNetwork] = useState(null)

    const [currentEdgeLabel, setCurrentEdgeLabel] = useState("")


    const spacePressed = useKeyPress('Shift', shiftDown, shiftUp); // Space bar flips
    const downArrowPressed = useKeyPress("ArrowDown", ()=>setNodeShape("text"), ()=>{})
    const upArrowPressed = useKeyPress("ArrowUp", ()=>setNodeShape("box"), ()=>{})
    const leftArrowPressed = useKeyPress("ArrowLeft", ()=>setNodeShape("ellipse"), ()=>{})
    const rightArrowPressed = useKeyPress("ArrowRight", ()=>setNodeShape("circle"), ()=>{})

    const [shiftHeldDown,setShiftHeldDown] = useState(false)
    const [firstNodeToConnect,setFirstNodeToConnect] = useState(-1)


    const [saveLockOn, setSaveLockOn] = useState(true)

    const [init, setInit] = useState(false)
    const [graphKey, setGraphKey]=useState(cuid())
    var resizeTimer;


    // // Make graph rerender
    function handleResize() {
      // Throttle calls resize
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        setGraphKey(graphKey=>cuid())
      }, 50);
    }


    useEffect(()=>{

      if (network!=null && props.nodes[0]!=null) {
        //
        if (!init) {
          setInit(true)
          window.addEventListener('resize', handleResize)
          network.focus(props.nodes[0].id)
        }
      }

    },[network, props.nodes])



    function shiftDown() {setShiftHeldDown(true)}
    function shiftUp() {setShiftHeldDown(false)}



  // Make a new edge
  function newEdge(from, to, label, id, type) {

      // if (props.edges.length == 0)
      //   label = "Edit me!"

      return {
        from: from,
        to: to,
        label:label,
        id:id,
        type:type,
      
      }
    }


  // Add a new node at the given coordinates
  function addNodeAtCoords(coords) {
    let title =  ""
    let nodeID = cuid()
    let defaultShape = "box"


    let node = {
        id: nodeID,
        label: title,
        x: coords.x,
        y: coords.y,
        shape:defaultShape,
        sideData: ""
      }

    let return_value = nodeID;

    props.setNodes(nodes => [...props.nodes, node])

    // Allow graph to update
    setTimeout(()=>{
      setselectedNodeID(return_value)

      setFirstNodeToConnect(return_value)
      network.selectNodes([return_value])
      console.log("node id: ",nodeID);
      fitNetworkOnNode(nodeID)

    }, 50)

    return return_value

  }

  // Called when a node is clicked on
  function nodeClicked(id, refit) {

    // Set the text in the text box to whatever the title
    // of this node is
    var title = props.nodes.filter((node)=>node.id == id)[0].label

    var sideBarData = props.nodes.filter((node)=>node.id == id)[0].sideData
    props.setSideBarData(sideBarData);

    //setSideBarData()
    // Connect nodes if shift is held down
    setFirstNodeToConnect(id)
    if (shiftHeldDown) {
      if (firstNodeToConnect == -1) {
      } else {
        connectNodes(id)
      }
    }

    setSelectedEdge(-1) // clear edge selection
    setselectedNodeID(id)

  }


  function connectNodes(secondNodeID) {
    var edge = newEdge(firstNodeToConnect, secondNodeID, "", cuid(), "arrow")
    props.setEdges(edges => [...props.edges, edge])

    let nodeLabel = props.nodes.filter(n => n.id==secondNodeID)[0].label

    setEdgeIDtracker(edgeIDtracker + 1)
    setFirstNodeToConnect(secondNodeID)
  }

  function connectTwoNodes(firstNodeID, secondNodeID) {
    var edge = newEdge(firstNodeID, secondNodeID, "", cuid(), "arrow")
    props.setEdges(edges => [...props.edges, edge])
    setEdgeIDtracker(edgeIDtracker + 1)
    setFirstNodeToConnect(secondNodeID)

  }

  function edgeClicked(edgeID) {

    setselectedNodeID(-1) // clear node selection

    var label = props.edges.filter((edge)=>edge.id == edgeID)[0].label
    setCurrentEdgeLabel(label)

    setSelectedEdge(edgeID)
  }



  function clickedEmptySpace(coords) {

    // If shift is held down, then add a new node
    // Connect it to the currrently selected node if
    // one exists
    if (shiftHeldDown) {

      let oldNodeID = selectedNodeID;
      let newNodeID = addNodeAtCoords(coords)

      setTimeout(()=> {
        // TODO This is causinig hte prob
        connectTwoNodes(oldNodeID, newNodeID)
        setselectedNodeID(newNodeID)
        network.selectNodes([newNodeID])
      },0)

    }
    // Clear selection otherwise
    else
      clearSelection()
  }

  // Don't have anything selected, clear text box
  function clearSelection(){
    setSelectedEdge(-1)
    setselectedNodeID(-1)
    setCurrentEdgeLabel("")
    setConnectingNodesOn(false)
    setFirstNodeToConnect(-1)
  }


  function getSelectedNode() {
    if (selectedNodeID != -1)
      return props.nodes.filter((node)=>node.id == selectedNodeID)[0];
    else
      return {}
  }

  function getSelectedEdge() {
    if (selectedEdge != -1)
      return props.edges.filter((e)=>e.id == selectedEdge)[0];
    else
      return {}
  }

  const alphaPressed = useAlphaNumbericPress(charPressed);
  const deleteKeyHeld = useDeletePress(deleteHit)

  function charPressed(char) {
    if (props.sideBarTextToggled)
      props.updateNodeSideData(char)
    else {
      // Check if a node or edge is selected, and update label appropriately
      if (selectedEdge != -1) {
        updateEdgeLabel(char)
      } else {
        updateNodeLabel(char)
      }
    }

  }

  function updateNodeLabel(newChar) {
    var currentNodeLabel = getSelectedNode().label
    setNodeTitle(currentNodeLabel + newChar)
  }

  function updateEdgeLabel(newChar) {
    var currentNodeLabel = getSelectedEdge().label
    setEdgeLabel(currentNodeLabel + newChar)
  }



  function deleteHit() {
    // Shift+delete deletes selection
    if (shiftHeldDown) {
      // Deletes the current selected node/edge
      if (selectedNodeID != -1)
        removeNode()

      if (selectedEdge != -1)
        removeEdge()
    } else {
        // Backspace on node text
        if (selectedNodeID != -1) {
            var currentNodeLabel = getSelectedNode().label
            setNodeTitle(currentNodeLabel.slice(0,-1))
        }

      if (selectedEdge !=-1) {
        var currentEdgeLabel = getSelectedEdge().label
        console.log("current label:", currentEdgeLabel);
        var newLabel = currentEdgeLabel.slice(0,-1)
        // this fixes a weird but where you couldn't delete the last character
        if (newLabel.length == 0) {
          newLabel = " "
        }
        setEdgeLabel(newLabel)
      }

    }
  }

  function removeNode() {
    setConnectingNodesOn(false)

    let nodeID = selectedNodeID
    if (nodeID != -1) {
      props.setNodes(nodes => props.nodes.filter(n => n.id != nodeID))
      // Need to remove edgesthat this node as to or from
      props.setEdges(edges => props.edges.filter(e=> e.to!=nodeID && e.from!=nodeID ))

      clearSelection()
      fitNetworkOnCurrentNode()
    }
  }

  function removeEdge() {
    setCurrentEdgeLabel("")
    setConnectingNodesOn(false)
    let edgeID = selectedEdge
    if (edgeID != -1) {
      props.setEdges(edges=>props.edges.filter(e=> e.id!=edgeID))
      //props.setEdges(edges=> edges:edges.filter(e=> e.id!=edgeID))
      clearSelection()
      //fitNetworkOnCurrentNode()
    }
  }


  // Zoom so that the whole graph can be seen
  function fitNetworkOnCurrentNode() {
  //  network.fit({ maxZoomLevel:5, animation:{duration:1000, easingFunction:"easeInOutCubic"}})
  //  network.focus(selectedNodeID)
  }

  function fitNetworkOnNode(nodeID) {
    network.focus(nodeID, { scale:2, animation:{duration:1000, easingFunction:"easeInOutCubic"}});
  }

  function connectExisting() {
    setConnectingNodesOn(true)
  }



  function setNodeTitle(text) {

    console.log("set node text", selectedNodeID);

    if (selectedNodeID != -1)
      props.setNodes(()=>props.nodes.map(n => n.id == selectedNodeID ? {...n, label:text} : n))
  }

  function setNodeShape(shape) {
    if (selectedNodeID != -1)
      props.setNodes(()=>props.nodes.map(n => n.id == selectedNodeID ? {...n, shape:shape} : n))
  }


  function setEdgeLabel(text) {

    // if (selectedEdge != -1)
      props.setEdges(()=>props.edges.map(e => e.id == selectedEdge ? {...e, label:text} : e))
    //
    // console.log("set edge label:",selectedEdge, text);
    // setCurrentEdgeLabel(text)
    //
    //
    // if (selectedEdge != -1) {
    //   props.setEdges(edges=>props.edges.map(e => {
    //     if (e.id == selectedEdge) {
    //         console.log("hit", e, "text:", text);
    //         return {...e, label:text}
    //
    //     }
    //
    //     else
    //       return e
    //   }))
    // }
  }

  // Update notes after a drag. PathwayGraph will call this on drag end event
  function updateNodePositions(nodeID) {

    let newPos = network.getPositions(nodeID)[nodeID]

    props.setNodes(nodes=>props.nodes.map(n=> {
      if (n.id == nodeID)
        return {...n, x:newPos.x, y:newPos.y}
      else
        return n
    }))
  }


  function test() {
    console.log("test");
  }



  // Changing the key value every time
  return (
    <div style={{}}>

    <OutsideAlerter onClickOutside={()=>clearSelection()}>
      <TanoGraph

        key={graphKey}
        click={()=>{}}
        setNetwork={setNetwork}
        addNodeAtCoords={addNodeAtCoords}
        selectedNodeID={selectedNodeID}
        nodeClicked={nodeClicked}
        clickedEmptySpace={clickedEmptySpace}
        edgeClicked={edgeClicked}
        updateNodePositions={updateNodePositions}
        graph = {{nodes:props.nodes,edges:props.edges}}
      />
    </OutsideAlerter>
    </div>
  )
}





function SideBarData(props) {
  return (
    <div/>
  )
}




// Main component for making and editing graphs. Grabs the
// graph data from the url parameter and loads it. Handles saving
function GraphEditor(props) {

  // Get graph id from url
  let { graphID } = useParams();

  const [graph, setGraph] = useState({ nodes: [], edges: []})
  //const [graph, setGraph] = useState({ nodes: [{ id: cuid(), label: "Click me!", shape: "box"}], edges: []})
  const [sideBarOpen, setSideBarShown] = useState(false)
  const [sideBarData, setSideBarData] = useState("")
  const [name, setName] = useState("")
  const [visible, setVisible] = useState(false);

  const [userID, setUserID] = useState("")

  const auth = getAuth();

// console.log("immediate id:", userID);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setUserID(user.uid)
    } else {
      // User is signed out
      // ...
    }
});


  // Load graph data from filebase
  // TODO: make sure user is the owner
  const getGraphData = async () => {
    const db = getFirestore();

    const docRef = doc(db, "graphs", graphID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setGraph({nodes: docSnap.data().nodes, edges: docSnap.data().edges});
      setName(docSnap.data().name)
    } else {
      console.log("No such document!");
    }

  }


  const saveGraphData = async () => {

    console.log("save graph data");

    const db = getFirestore();
    await setDoc(doc(db, "graphs", graphID), {
      name: name,
      nodes: graph.nodes,
      edges: graph.edges,
    });



    // Update graph list item
    console.log("userID:", userID);
    const graphListRef = doc(db, "graph_lists", userID);
    await updateDoc(graphListRef, {
      [graphID]: {name: name, num_nodes:graph.nodes.length}
    })

  }


  useEffect(() => {
    getGraphData()
  }, [])



  function setNodes(nodeProducer) {
    setGraph(graph=>{return{...graph, nodes:nodeProducer()}})
    //saveGraphData()
  }

  function setEdges(edgeProducer) {
    setGraph(graph=>{return{...graph, edges:edgeProducer()}})
  //  saveGraphData()
  }



  const toggleSideBar = () => setVisible(!visible);
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);



  // Enter key toggles side bar
  const enterPressed = useKeyPress('Enter', toggleSideBar, ()=>{});


  return (

    <div className={"mainpane"} style={{height:"100vh"}}>
      <NavBar />

       <PathwayMakerInner
           nodes={graph.nodes}
           setNodes={setNodes}
           edges={graph.edges}
           setEdges={setEdges}
           name={name}
           setName={setName}
           modalOpen={props.modalOpen}
           setModalOpen={props.setModalOpen}
           studying={false}
           setSideBarData={setSideBarData}
           sideBarTextToggled={false}
           setSideBarTextToggled={()=>{}}
       />



      <div className="bottom-bar">
        <Stack gap={1}  direction="horizontal" >
          <Form.Control style={{width:"300px"}} className="email text-center" onChange={ev => setName(ev.target.value)} placeholder="name" value={name} / >

          <div style={{width:"100px"}}>
          <PrimaryButton onClick={()=>saveGraphData()}> save </PrimaryButton>
          </div>

        </Stack>
      </div>


    </div>
  )

}


export default GraphEditor;
