import "./about.css"
import {PrimaryButton} from '../components/Buttons';
import { getAuth, signInWithEmailAndPassword, signOut,onAuthStateChanged, currentUser } from "firebase/auth";
import NavBar from "../components/Nav";
import { useNavigate } from "react-router-dom";
import CheckLoggedIn from "../components/CheckLoggedIn";



function MainContent() {
  const auth = getAuth();
  const navigate = useNavigate()



  return (
    <div class="info-container">
      <h1>Welcome</h1>
      <p>Tano helps you memorize complex information more easily by allowing you to create graphs of information</p>
      <br/>
      <h1>Creating a graph</h1>
      <p>- To create a new node, double click anywhere on the canvas</p>
      <p>- To change the next of a node, select it (the background will be solid) and start typing</p>
      <p>- To connect two nodes, click the first node you want to connect, then hold the shift key while you select the second node</p>
      <p>- You can create graphs more quickly by holding down the shift key and clicking empty space, which will connect the currently selected node to a new node</p>
      <br/>
      <h1>Studying</h1>
      <p>When you first start to study a graph, you'll see a node with a question mark. Pressing the spacebar or enter keys will reveal the node. Pressing space or enter again will move to the next node in the graph. The left and right arrow keys can also be used to move back and forth in the graph.</p>
      <br/>
      <h1>Find a bug?</h1>
      <p>We're on it! Please let us know at jackduryea@gmail.com</p>
      <br/>
      <br/>
      <br/>
      <p>Tano was created by Jack Duryea</p>
    </div>
  )

}



function AboutPage() {
  return (
      <div>
        <NavBar/>
        <MainContent />
      </div>

  )
}

export default AboutPage
