import Stack from 'react-bootstrap/Stack';
import React, {Component,useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";

import "./Nav.css"

function MainLogo() {

  /*
  This component displays the main logo in the nav bar.
  */
  return (
    <h3 className="mainlogo"> TANO</h3>
  )
}


function NavItem(props) {
  /* A single item in a nav bar that can be clicked on
    to take the user to the specified route

    Props
    value: string, name of the item to display
    route: string, the route that should be navitated to.
   */

   const navigate = useNavigate()

   return (
     <p className="navitem"  onClick={()=>navigate(props.route)} >{props.value}</p>
   )

}

function NavBar() {
  // TODO: need routes for all of these
  return (
    <div className="navbar">
        <Stack direction={"horizontal"} gap={5}>
          <MainLogo/>
          <NavItem value={"home"} route={"/home"}/>

          <NavItem value={"profile"} route={"/profile"}/>
          <NavItem value={"about"} route={"/about"}/>
        </Stack>
    </div>
  )
}

export default NavBar;
